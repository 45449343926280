/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */

body {
  background-color: $color1 !important;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul li {
  list-style: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.App {
  min-height: 100vh;
}

section {
  min-height: 70vh;
}

.main-content {
  overflow-x: hidden;
  // @include to-tablet-sm {
  //   $navbar-height: 62px;
  //   padding-top: $navbar-height;
  // }
  @include from-tablet-lg{
    padding-top: $navbar-height-tablet-lg;
  }
  @include from-desktop-sm {
    padding-top: $navbar-height-desktop-sm;
  }
  @include from-desktop-lg {
    padding-top: $navbar-height-desktop-lg;
  }
}

/* -------------------------------------------------------------------------- */
/*                                   Custom Classes                           */
/* -------------------------------------------------------------------------- */
.text--green {
  color: $color4;
}

.text--yellow {
  color: $yellow;
}

.text--white {
  color: $white;
}

.text--main-blue {
  color: $color1;
}

.bg--main-blue {
  background-color: $color1;
}

.bg--main-green {
  background-color: $color4;
}

.bg--main-stars {
  background-image: url('../../Images/bg-stars.png');
}

/*************************************************************************/
/*                               Override                                */
/*************************************************************************/
// /* Start react-toastify override */
// .Toastify__toast--success .Toastify__toast-icon svg {
//   fill: var(--primary-1);
// }

// .Toastify__progress-bar--success {
//   background: var(--primary-1) !important;
// }
// /* End react-toastify override */
