.no-internet {
  color: #fff;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  gap: 57px;
  text-align: center;
  max-width: 100%;

  img {
    max-width: 100%;
  }
  //   overflow: hidden;

  .text-container {
    width: 576px;

    [lang='ar'] & {
      width: 420px;
    }
    [lang='en'] & {
      width: 476px;
    }

    max-width: 100%;
    h1 {
      color: #176b87;
      font-size: 30px;
      line-height: 54px;

      @include from-tablet-sm {
        font-size: 52px;
        line-height: 68px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;

      @include from-tablet-sm {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  button {
    background-color: #60ec2f;
    color: #fff;
    border-radius: 16px;
    padding: 8px 16px;

    font-size: 25.68px;
    line-height: 33.7px;
    font-weight: 700;

    min-width: 226.27px;
    height: 48.84px;

    @include from-tablet-sm {
      width: 282px;
      height: 58px;
      font-size: 32px;
      line-height: 42px;
    }

    @include from-tablet-sm {
      width: 410px;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    outline: none !important;
    border: none !important;

    &:hover,
    &:focus {
      background-color: #60ec2f !important;
      color: #fff !important;
      outline: none !important;
      border: none !important;
    }
  }
}
