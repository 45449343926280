.invite-friends {
  background-color: white;
  @include from-tablet-lg {
    background-color: #f5f5f5;
    padding: 30px 20px;
  }
  &__holder {
    @include from-tablet-lg {
      max-width: 744px;
    }
    &__header {
      min-height: 304px;
      padding-inline: 22px;
      background-color: #0f3264;
      @include from-tablet-sm {
        min-height: 469px;
        padding-inline: 0;
      }
      &__lang-btn-holder {
        top: 40px;
        right: 24px;
        @include from-tablet-sm {
          right: 40px;
        }
        &[data-lang='ar'] {
          right: unset;
          left: 24px;
          @include from-tablet-sm {
            left: 40px;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          @include from-tablet-sm {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    &__body {
      background-color: white;
      padding: 32px 20px;
      gap: 30px;
      @include from-tablet-sm {
        gap: 35px;
        padding: 56px 40px;
      }
      @include from-tablet-lg {
        gap: 40px;
      }
      h1 {
        color: #0f3264;
        letter-spacing: 1px;
        @include from-tablet-sm {
          font-size: 1.5rem;
        }
      }
      p {
        color: #0c0c0c;
      }
    }
    &__form {
      gap: 36px;
      @include from-tablet-sm {
        gap: 56px;
      }
      @include from-tablet-lg {
        gap: 40px;
      }
      &__phone-input-holder {
        margin-bottom: 12px;
        @include from-tablet-sm {
          margin-bottom: 0;
        }
        &__country-select {
          height: 56px;
          border-radius: 8px;
          padding-inline-end: 8px;
          background-color: #f5f5f5;
          @include from-tablet-sm {
            height: 64px;
            padding-right: 25px;
            padding-inline-end: 18px;
          }
          img {
            width: 24px;
            @include from-tablet-sm {
              width: 36px;
            }
          }
          span {
            font-weight: bold;
            color: #001c30;
            font-size: 15px;
            @include from-tablet-sm {
              font-size: 22px;
            }
          }
        }
        &__input {
          border-radius: 8px;
          padding-left: 20px;
          color: $color1 !important;
          height: 56px;
          @include from-tablet-sm {
            height: 64px;
          }
          &,
          &:hover,
          &:focus,
          &:focus-within {
            background-color: #f5f5f5 !important;
            border-color: #f5f5f5;
            box-shadow: none;
            &:-webkit-autofill {
              -webkit-box-shadow: 0 0 0 90px #f5f5f5 inset !important;
            }
          }
          &::placeholder {
            color: #8f8f8f;
          }
          &[data-lang='ar'] {
            &::placeholder {
              text-align: right;
            }
          }
        }
      }
      &__submit-btn {
        height: 56px;
        border-radius: 8px;
        letter-spacing: 1px;
        background-color: #0db14b;
        color: white;
        transition: background-color 0.3s ease;
        &:not(:disabled):hover {
          background-color: #0db14b !important;
          color: white !important;
        }
        &:disabled {
          color: #8f8f8f;
        }
        @include from-tablet-sm {
          height: 64px;
          margin-top: 35px;
        }
        @include from-tablet-lg {
          margin-top: 0;
        }
      }
    }
  }
}
