.header {
  max-width: 100%;

  @include from-tablet-lg {
    position: fixed !important;
    top: inset;
    z-index: 1030;
    width: 100%;
  }

  .navbar {
    & {
      padding: 16px 20px;
      @include from-tablet-sm {
        padding: 32px 40px;
      }
      @include from-desktop-sm {
        padding: 13px 34.5px 10px 42.5px;
      }
      @include from-desktop-lg {
        padding: 19px 34.5px 19px 42.5px;
      }
    }

    &__logo {
      width: 40px;
      @include from-tablet-sm {
        width: 64px;
      }
      @include from-desktop-sm {
        width: 74px;
      }
    }

    &-toggler {
      &__icon {
        width: 28px;
        height: 28px;
        @include from-tablet-sm {
          width: 52px;
          height: 52px;
        }
      }
    }

    .navbar-nav {
      gap: 40px;
      // gap: 20px;
      // overflow-y: auto;
      display: flex;
      align-items: flex-start;
      @include to-tablet-lg {
        padding-top: 31px;
        padding-bottom: 16px;
      }
      @include from-tablet-lg {
        align-items: center;
        gap: 16px;
      }
    }

    .nav-link {
      gap: 12px;
      @include from-tablet-lg {
        gap: 8px;
      }
      &__icon {
        width: 40px;
        height: 40px;
        @include to-tablet-sm {
          width: 24px;
          height: 24px;
        }
        fill: $white;
        transition: fill 0.3s ease;
      }
      &__title {
        transition: color 0.3s ease;
      }
      &.active,
      &:hover {
        svg,
        path {
          fill: $color4;
        }
        .nav-link__icon {
          fill: $color4;
        }
        .nav-link__title {
          color: $color4;
        }
      }
    }
  }
}
