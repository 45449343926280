.terms {
  padding-block: 40px;
  @include from-tablet-sm {
    padding-block: 100px;
  }
  h1 {
    margin-bottom: 28px;
    @include from-tablet-sm {
      margin-bottom: 52px;
    }
    @include from-desktop-sm {
      margin-bottom: 64px;
    }
  }

  p {
    padding-inline: 40px;
    @include from-desktop-sm {
      width: 1048px;
    }
  }

  &__pdf-holder {
    width: fit-content;
    margin: 0 auto;
    border-radius: 12px;
  }
}

.terms-modal {
  &-mask {
    z-index: 1060 !important;
  }
  &__wrap {
    z-index: 1400 !important;
  }
  .ant-modal-body {
    overflow-y: auto;
    max-height: 70vh;
    min-height: 200px;
    position: relative;
  }
}
