.home {
  @include from-tablet-lg {
    padding-top: $navbar-height-tablet-lg;
  }
  @include from-desktop-sm {
    padding-top: $navbar-height-desktop-sm;
  }
  @include from-desktop-lg {
    padding-top: $navbar-height-desktop-lg;
  }
  &__first-section {
    height: 630px;
    background-size: cover;
    background-position: center;
    background-image: url('../../Images/Home/first-section--sm.png');
    @include from-tablet-sm {
      background-image: url('../../Images/Home/first-section--md.png');
    }
    @include from-desktop-sm {
      background-image: url('../../Images/Home/first-section--lg.png');
    }
    &--rotate {
      transform: rotateY(-180deg);
    }
    &__text-holder {
      height: 100%;
      @include from-tablet-sm {
        -webkit-backdrop-filter: blur(18px);
        backdrop-filter: blur(18px);
        background-color: #00000033;
        width: 417px;
      }
      @include from-desktop-sm {
        width: 605px;
      }
      &__holder {
        gap: 56px;
        @include to-tablet-sm {
          padding-inline: 27px;
          // width: 400px;
          // background-color: red;
        }
        @include from-tablet-sm {
          //  width: 299px;
          width: 350px;
        }
        @include from-desktop-lg {
          // width: 389px;
          width: 500px;
        }
        h1 {
          @include to-tablet-sm {
            max-width: 292px;
            // font-size: 2.5rem;
            font-size: 2rem;
            line-height: 52px;
          }
          @include from-tablet-sm {
            font-size: 2.5rem;
            line-height: 52px;
          }
          @include from-desktop-lg {
            font-size: 3.25rem;
            line-height: 68px;
          }
        }
        p {
          @include to-tablet-sm {
            max-width: 224px;
          }
        }
      }
      &__about-link {
        width: 205px;
        height: 52px;
        @include from-tablet-sm {
          width: 227px;
        }
        @include from-desktop-lg {
          width: 282px;
          height: 58px;
        }
        border-radius: 16px;
        padding: 8px 16px;
        background-color: $color4;
        &:hover {
          color: $white;
        }
      }
    }
    &--rotate {
      .home__first-section__text-holder {
        transform: rotateY(-180deg);
      }
    }
  }

  &__second-section {
    padding-block: 40px;
    @include to-tablet-sm {
      padding-inline: 50px;
    }
    @include from-tablet-sm {
      padding-block: 100px;
    }
    &__cols {
      & > div:first-of-type {
        margin-bottom: 40px;
        @include from-tablet-sm {
          margin-bottom: 100px;
        }
        @include from-desktop-sm {
          margin-bottom: 0;
        }
      }
    }
    &__col {
      gap: 16px;
      @include from-tablet-sm {
        gap: 24px;
      }
      img {
        max-width: 100%;
        @include from-tablet-sm {
          width: 350px;
        }
      }
      p {
        @include from-tablet-sm {
          width: 368px;
        }
      }
    }
  }

  &__third-section {
    padding-bottom: 100px;
    @include to-desktop-sm {
      padding-inline: 100px;
    }
    @include to-tablet-sm {
      padding: 30px 51px 40px;
    }
    @include from-desktop-sm {
      gap: 40px;
    }
    &__hero {
      @include to-desktop-sm {
        margin-bottom: 100px;
      }
      @include to-tablet-sm {
        margin-bottom: 40px;
      }
      @include from-desktop-sm {
        width: 726.45px;
      }
    }
    &__text-holder {
      gap: 16px;
      @include from-tablet-sm {
        gap: 80px;
      }
      @include from-desktop-sm {
        width: 360px;
      }
      @include from-desktop-lg {
        width: 495px;
      }
      &__links {
        gap: 18px;
        @include from-tablet-sm {
          gap: 24px;
        }
        img {
          width: 201px;
          @include from-tablet-sm {
            width: 269px;
          }
        }
      }
    }
  }
}
