.supply-prices {
  padding: 20px 10px 77px;
  @include from-tablet-sm {
    padding: 40px 20px 63px;
  }
  @include from-desktop-sm {
    padding: 80px 64px 100px;
  }
  &__loading-holder {
    img {
      width: 150px;
    }
  }
  &__holder {
    row-gap: 8px;
    @include from-tablet-sm {
      row-gap: 24px;
    }
    @include from-desktop-sm {
      row-gap: 32px;
    }
    .col-4 {
      @include to-tablet-sm {
        padding-inline: 10px !important;
      }
    }
    &__data-holder {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color4;
      padding: 3px;
      border-radius: 4px;
      &--title {
        background-color: $color4;
        color: white;
        min-height: 27px;
      }
      &--value {
        background-color: white;
        border: 1px solid $color4;
        color: #0f3264;
        word-wrap: break-word;
        min-height: 36px;
      }
      @include to-tablet-sm {
        font-size: 0.625rem;
        line-height: 10px;
      }
      @include from-tablet-sm {
        border-radius: 8px;
        min-height: 56px;
      }
    }
  }
}
