// .contact-us__section {
//   background: #001c30;
//   width: 100%;
//   max-width: 1000px;
//   border-radius: 10px;
//   margin: auto;
//   background-image: url('../../Images/Contact/phone contact.png');
//   background-position: bottom right;
//   background-repeat: no-repeat;
//   border: 1px solid rgba(255, 255, 255, 0.5);

//   @include from-tablet-sm {
//     // width: 80%;
//     width: 95;
//     background-image: url('../../Images/Contact/tablet contact.png');
//   }

//   @include from-desktop-sm {
//     width: 80%;
//     background-image: url('../../Images/Contact/background contact.png');
//   }

//   .contact-us {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: center;
//     gap: 32px;
//     width: 300px;
//     margin: 38px auto 27px;
//     // background-color: red;

//     &__input {
//       width: calc(300px);
//       // width: 300px;
//       max-width: 90%;
//     }

//     &__field-group {
//       // position: relative;
//       display: flex;
//       gap: 32px;

//       &__input {
//         width: 150px;
//         max-width: 70%;
//       }
//     }

//     .submit-btn {
//       align-self: center;
//       background-color: $color4;
//       border-radius: 8px;
//       color: #fff;
//       font-weight: 700;
//       font-size: 11px;
//       line-height: 14px;
//       width: 183px;
//       height: 36px;
//       border: none;
//       outline: none;
//       padding: 10px;
//       &:disabled {
//         opacity: 0.5;
//       }

//       &:hover,
//       &:focus {
//         background-color: $color4 !important;
//         color: #fff !important;
//       }
//     }

//     @include from-tablet-sm {
//       & {
//         width: 500px;
//         margin: 68px auto 47px;

//         &__input {
//           width: calc(500px + 64px);
//         }

//         &__field-group {
//           &__input {
//             width: 250px;
//           }
//         }
//       }

//       .submit-btn {
//         width: 384px;
//         height: 46px;
//         font-size: 20px;
//         line-height: 26px;
//       }
//     }

//     @include from-desktop-lg {
//       & {
//         width: 800px;

//         &__input {
//           width: calc(800px + 64px);
//           // width: 800px;
//         }

//         &__field-group {
//           &__input {
//             width: calc(400px + 32px);
//             // width: 400px;
//           }
//         }
//       }

//       .submit-btn {
//         width: 578px;
//         height: 46px;
//         font-size: 20px;
//         line-height: 26px;
//       }
//     }
//   }
// }

.contact-us__section {
  width: 90%;
  margin: 50px auto;

  background: #001c30;
  background-image: url('../../Images/Contact/phone contact.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 2rem;

  @include from-tablet-sm {
    width: 80%;
  }

  @include from-tablet-lg {
    width: 60%;
  }

  .contact-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .contact-us__title {
      color: #fff;
    }

    input,
    textarea {
      width: 100%;
    }

    .submit-btn {
      align-self: center;
      background-color: $color4;
      border-radius: 8px;
      color: #fff;
      font-weight: 700;
      font-size: 11px;
      line-height: 14px;
      width: 183px;
      height: 36px;
      border: none;
      outline: none;
      padding: 10px;
      &:disabled {
        opacity: 0.5;
      }

      &:hover,
      &:focus {
        background-color: $color4 !important;
        color: #fff !important;
      }

      @include from-tablet-sm {
        width: 384px;
        height: 46px;
        font-size: 20px;
        line-height: 26px;
      }

      @include from-desktop-lg {
        width: 578px;
        height: 46px;
        font-size: 20px;
        line-height: 26px;
      }
    }

    .contact-us__field-group {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      @include from-tablet-sm {
        flex-direction: row;
        justify-content: space-between;

        select:nth-child(2) {
          margin-top: 4px;
        }
        .contact-us__field-group__input,
        select:nth-child(2) {
          width: 250px;
        }
      }

      @include from-tablet-lg {
        .contact-us__field-group__input,
        select:nth-child(2) {
          width: 260px;
        }
      }

      @include from-desktop-sm {
        .contact-us__field-group__input,
        select:nth-child(2) {
          width: 350px;
        }
      }
      @include from-desktop-lg {
        .contact-us__field-group__input,
        select:nth-child(2) {
          width: 380px;
        }
      }
      @include from-desktop-xl {
        .contact-us__field-group__input,
        select:nth-child(2) {
          width: 420px;
        }
      }
      @include from-larger-screens {
        .contact-us__field-group__input,
        select:nth-child(2) {
          width: 520px;
        }
      }
    }
  }
}
