.privacy-policy {
  color: #fff;
  padding: 40px 38px;
  @include from-tablet-sm {
    padding: 100px 40px;
  }
  @include from-desktop-sm {
    padding: 100px 196px;
  }
  &__main-title {
    margin-bottom: 28px;
    @include from-tablet-sm {
      margin-bottom: 52px;
    }
    @include from-desktop-lg {
      margin-bottom: 64px;
    }
  }

  .privacyPolicy__list {
    // list-style:  !important;
    padding-inline: 2rem;
    li {
      list-style: disc;
    }
  }
}
