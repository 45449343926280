.footer {
  padding-block: 40px;
  &__holder {
    // width: 477px;
    width: 340px;
    max-width: 100%;
    @include to-tablet-sm {
      max-width: 470px;
    }
    @include from-tablet-sm {
      width: 700px;
    }
    @include from-desktop-sm {
      width: 1140px;
    }
    &__social-links {
      gap: 17px;
      padding-top: 32px;
      @include from-desktop-sm {
        padding-top: 0;
      }
      img {
        width: 20px;
      }
    }
    &__nav-links {
      margin-bottom: 52px;
      gap: 72px;
      @include to-tablet-sm {
        gap: 52px 32px;
        margin-bottom: 61px;
      }
      a,
      button {
        transition: color 0.3s ease;
      }
      a:hover,
      button:hover {
        color: $color4;
      }
      & > li:not(:last-child) {
        position: relative;
        &::after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $white;
          position: absolute;
          top: 50%;
        }
        &.lang {
          &--en {
            &::after {
              right: -36px;
              transform: translate(50%, -50%);
              @include to-tablet-sm {
                right: -16px;
              }
            }
          }
          &--ar {
            &::after {
              left: -36px;
              transform: translate(-50%, -50%);
              @include to-tablet-sm {
                left: -16px;
              }
            }
          }
        }
        @include to-tablet-sm {
          &:nth-child(3) {
            &::after {
              content: none;
              width: 0;
              height: 0;
            }
          }
        }
      }
    }
    &__get-app-links-col {
      @include to-desktop-sm {
        margin-bottom: 52px;
      }
      &__holder {
        // width: 242px;
        gap: 16px;
        img {
          width: 200px;
        }
      }
    }
    &__copy-rights-col {
      & > div {
        width: 232px;
        gap: 20px;
      }
      &__logo {
        width: 74px;
      }
    }
  }
}

.languages-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    p {
      color: $color4 !important;
    }
    svg,
    path {
      fill: $color4;
    }
  }

  p {
    transform: translateY(0.5rem);
  }

  &--footer {
    gap: 12px;
    @include from-tablet-sm {
      gap: 8px;
    }
    .language-btn__icon {
      width: 24px;
      height: 24px;
    }
  }

  &__icon {
    fill: $white;
    transition: fill 0.3s ease;
  }
  &__title {
    transition: color 0.3s ease;
  }
  @include from-desktop-sm {
    &:hover {
      .language-btn__icon {
        fill: $color4;
      }
      .language-btn__title {
        color: $color4;
      }
    }
  }

  .languages-list {
    position: absolute;
    bottom: -135px;
    border-radius: 3px;

    background-color: #fff;
    width: 110px;
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5rem;

    li {
      // background-color: red;
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: 100%;
      text-align: start;
      span {
      }
    }
  }

  &--footer {
    background-color: transparent;
    border: none;
  }

  &--footer {
    @include from-tablet-lg {
      // flex-direction: row;
    }
  }
}
