/*************************************************************************/
/*                                Colors                                  */
/*************************************************************************/
$color1: #001c30;
$color2: #031830;

$color3: #176b87;
// $color4: #589559;
$color4: #60ec2f;
$yellow: #b9ff66;

$white: #ffffff;

/*************************************************************************/
/*                                Sizes                                  */
/*************************************************************************/
@for $i from 1 through 16 {
  .mb--#{$i} {
    margin-bottom: #{$i * 4}px;
  }
}

/*************************************************************************/
/*                             Components                                */
/*************************************************************************/
$navbar-height-desktop-lg: 106.6px;
$navbar-height-desktop-sm: 91.16px;
$navbar-height-tablet-lg: 126px;
