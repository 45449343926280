.about {
  &__first-section,
  &__second-section {
    .paragraph {
      font-size: 24px;
      line-height: 42px;
    }
    background-size: cover;
    position: relative;
    contain: content;
    padding: 12px 20px;
    gap: 12px;
    @include from-tablet-sm {
      padding: 40px 40px 48px;
      gap: 40px;
    }
    @include from-desktop-sm {
      padding: 52px 120px 43px;
      gap: 52px;
    }
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
    }
  }

  &__first-section {
    background-image: url('../../Images/About/first-section--mobile.png');
    @include from-tablet-sm {
      background-image: url('../../Images/About/first-section--tablet.png');
    }
    @include from-desktop-sm {
      background-image: url('../../Images/About/first-section--lg.png');
    }
    &::after {
      background-color: rgba(0, 28, 48, 0.85);
    }
  }

  &__second-section {
    background-image: url('../../Images/About/second-section--mobile.png');
    @include from-tablet-sm {
      background-image: url('../../Images/About/second-section--tablet.png');
    }
    @include from-desktop-sm {
      background-image: url('../../Images/About/second-section--lg.png');
    }
    &::after {
      background-color: rgba(0, 95, 36, 0.85);
    }

    .what-we-do {
      font-weight: 700;
      font-size: 40px;
      line-height: 68px;
    }
  }

  &__mission-and-vision {
    padding: 70px 60px;
    position: relative;
    display: flex;
    justify-content: space-between;

    @include to-tablet-lg {
      gap: 40px;
    }

    &::after {
      position: absolute;
      content: '';
      left: 44%;
      top: 50%;
      background-color: #fff;
      width: 1px;
      height: 50%;
      transform: translate(-50%, -50%);

      @include to-tablet-lg {
        display: none;
      }

      [dir='rtl'] & {
        left: auto; // Remove left positioning
        right: 44%; // Apply right positioning instead
      }

      [lang='hr'] & {
        right: 55%;
      }
    }

    div {
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 28px;
      }

      @include from-tablet-lg {
        width: 80%;
      }

      gap: 40px;

      p {
        font-size: 24px;
        // line-height: 28px;
        line-height: 1.3;
        @include from-tablet-lg {
          width: 80%;
        }
      }
    }

    @include to-tablet-lg {
      flex-direction: column;
    }
  }

  &__join-section {
    background-image: url('../../Images/About/third-section--mobile.png');
    height: 705px;
    @include to-tablet-sm {
      display: flex;
      align-items: flex-end;
      background-position: bottom;
    }
    @include from-tablet-sm {
      height: 362px;
      background-size: cover;
      background-image: url('../../Images/About/third-section--tablet.png');
    }
    @include from-desktop-sm {
      height: 539px;
      background-image: url('../../Images/About/third-section--lg.png');
    }
    &--rotate {
      transform: rotateY(-180deg);
    }
    &__text-holder {
      font-style: italic;
      background-color: #00000066;
      -webkit-backdrop-filter: blur(14px);
      backdrop-filter: blur(14px);
      padding-left: 20px;
      padding-right: 20px;
      gap: 16px;
      height: 50%;
      @include from-tablet-sm {
        gap: 24px;
        height: 100%;
        width: 349px;
        padding-left: 40px;
        padding-right: 22px;
      }
      @include from-tablet-lg {
        font-style: normal;
      }
      @include from-desktop-sm {
        background-color: transparent;
        -webkit-backdrop-filter: blur(0);
        backdrop-filter: blur(0);
        gap: 52px;
        width: 714px;
        padding-left: 120px;
        padding-right: 115px;
      }
      h2 {
        font-size: 20px;
        line-height: 26px;
        span {
          &:first-of-type {
            font-weight: 700;
            @include from-tablet-sm {
              font-size: 20px;
              line-height: 28px;
            }
            @include from-desktop-sm {
              font-size: 40px;
              line-height: 42px;
            }
          }
          &:last-of-type {
            font-weight: 400;
            @include from-tablet-sm {
              font-size: 28px;
              line-height: 36px;
            }
            @include from-desktop-sm {
              font-size: 48px;
              line-height: 64px;
            }
          }
        }

        span {
          font-weight: 500;
        }
      }

      p {
        font-size: 18px;
        line-height: 36px;
        font-weight: 500;

        @include from-desktop-sm {
          font-size: 28px;
          line-height: 36px;
        }
      }

      // h2 {
      //   font-size: 40px;
      //   line-height: 42px;

      //   span {
      //     &:first-of-type {
      //       font-weight: 700;
      //     }
      //     &:last-of-type {
      //       font-weight: 400;
      //     }
      //   }
      // }

      // p {
      //   font-weight: 500;
      //   font-size: 28px;
      //   line-height: 36px;
      // }
    }
    &--rotate {
      .about__join-section__text-holder {
        transform: rotateY(-180deg);
      }
    }
  }

  &__our-core {
    background: #01243d;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 93px 56px;

    h2 {
      margin-bottom: 46px;
      font-weight: 700;
      // margin-left: px;
      @include from-desktop-sm {
        margin-left: 55px;
      }

      [lang='ar'] & {
        margin-right: 55px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;

      @include from-tablet-lg {
        flex-direction: row;
      }
    }

    &__value-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include from-tablet-lg {
        width: 38%;
        min-height: 233px;
      }

      @include from-desktop-lg {
        width: 30%;
        min-height: 250px;
      }

      background-color: $color1;
      padding: 1.25rem 3rem;
      border-radius: 16px;

      h3 {
        width: 100%;
        font-size: 20px;
        line-height: 30px;
        position: relative;
        z-index: 2;
        margin-bottom: 16px;

        &::before {
          position: absolute;
          content: '';
          width: 40px;
          height: 40px;
          background-color: $color4;
          border-radius: 50%;
          left: -20px;
          top: -20%;
          z-index: -1;

          [dir='rtl'] & {
            right: -20px;
          }
        }
      }
      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }

  &__video-section {
    padding: 20px 20px 50px;
    @include from-tablet-sm {
      padding: 40px 45px 92px;
    }
    @include from-desktop-sm {
      padding: 52px 80px 100px;
    }
    &__holder {
      border-radius: 16px;
      padding: 16px;
      border: 4px solid #fff;
      @include from-tablet-sm {
        border: 8px solid #fff;
        padding: 40px;
      }
      &__text-holder {
        gap: 16px;
        @include from-tablet-sm {
          gap: 24px;
        }
        // &__title {
        //   font-size: 32px;
        //   line-height: 42px;
        // }
        // &__text {
        //   font-size: 28px;
        //   line-height: 36px;
        // }
        &__text,
        &__title {
          font-size: 1rem;
          @include from-tablet-sm {
            font-size: 1.25rem;
          }
          @include from-desktop-sm {
            font-size: 1.75rem;
          }
        }
      }
      &__video-holder {
        video {
          background-size: cover;
        }
        &__video-overlay {
          -webkit-transition: opacity 0.5s ease;
          -o-transition: opacity 0.5s ease;
          transition: opacity 0.5s ease;
          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          button {
            z-index: 5;
          }
          &.hide {
            opacity: 0;
          }
        }
      }
    }
  }
}
