.language-btn {
  position: relative;
  z-index: 1030;

  // @include from-tablet-lg {
  //   top: 0.9rem;
  // }

  &:hover {
    span {
      color: $color4 !important;
    }
    svg,
    path {
      fill: $color4;
    }
  }
  &--nav {
    gap: 12px;
    @include from-tablet-lg {
      gap: 8px;
    }
    .language-btn__icon {
      width: 40px;
      height: 40px;
      @include to-tablet-sm {
        width: 24px;
        height: 24px;
      }
    }
  }
  &--footer {
    gap: 12px;
    @include from-tablet-sm {
      gap: 8px;
    }
    .language-btn__icon {
      width: 24px;
      height: 24px;
    }
  }
  &__icon {
    fill: $white;
    transition: fill 0.3s ease;
  }
  &__title {
    transition: color 0.3s ease;
  }
  @include from-desktop-sm {
    &:hover {
      .language-btn__icon {
        fill: $color4;
      }
      .language-btn__title {
        color: $color4;
      }
    }
  }
  &__dropdown-holder {
    li {
      padding: 0 !important;
    }
    &__item {
      padding: 5px 12px;
    }
  }
  .language-list {
    // style={{
    //   transform:
    //     language === 'ar' ? 'translate(20%)' : 'translateX(-20%)',
    // }}
    // position: absolute;
    // bottom: -110px;

    // transform: translateX(-20%);

    // [lang='ar'] & {
    //   transform: translateX(20%) !important;
    // }

    // @include to-tablet-lg {
    //   [lang='hr'] & {
    //     transform: translateX(50%) !important;
    //   }
    // }

    border-radius: 3px;
    background-color: #fff;
    list-style: none;
    width: 110px;
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5rem;
    li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: 100%;
      text-align: start;
    }
  }

  // &--nav,
  // &--footer {
  //   background-color: transparent;
  //   border: none;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   transform: translateY(0.25rem);
  // }

  &--nav {
    @include from-tablet-lg {
      flex-direction: column;
    }
    // svg {
    //   margin-top: -1rem;
    // }

    .language-list {
      // right: -4rem;
      // bottom: -120px;

      @include from-tablet-lg {
        // right: -1rem;
      }
    }
  }
  &--footer {
    @include from-tablet-lg {
      // flex-direction: row;
    }
  }
}

// [dir='ltr'] .language-list {
//   right: -100%;
// }

// /* Styles for RTL (Arabic) */
// [dir='rtl'] .language-list {
//   left: -100%; /* Position it to the left */
// }
