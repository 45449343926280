.page-404 {
  padding-block: 89px;
  @include to-tablet-sm {
    padding-inline: 34px;
  }
  &__main-image {
    @include to-tablet-sm {
      width: 243.75px;
      margin-bottom: 46px;
    }
    @include from-tablet-sm {
      width: 487.5px;
      margin-bottom: 72px;
    }
    @include from-desktop-sm {
      width: 650px;
    }
  }
  &__main-heading {
    margin-bottom: 24px;
    @include to-tablet-sm {
      margin-bottom: 12px;
    }
  }
  &__main-paragraph {
    margin-bottom: 72px;
    @include to-tablet-sm {
      margin-bottom: 46px;
    }
    @include from-tablet-sm {
      width: 374px;
    }
  }
  &__main-link {
    &:hover {
      color: $white;
    }
    @include to-tablet-sm {
      width: 277px;
    }
    @include from-tablet-sm {
      width: 320px;
    }
    @include from-desktop-sm {
      width: 282px;
    }
    border-radius: 16px;
    padding: 8px 12px;
  }
}
