.custom-outlined-input {
  label {
    font-size: 14px;
    line-height: 11px;
    font-weight: 400;

    @include from-tablet-sm {
      font-size: 16px;
      line-height: 20px;
    }
  }

  textarea {
    // height: 30px;
    &::placeholder {
      padding-inline: 1px;
    }
  }

  input,
  select {
    // width: 100% !important;
    padding-bottom: 12px;
    &::placeholder {
      color: #8d8d8d;
      display: block;
      padding: 0.25rem 0;
    }
  }

  input:focus,
  textarea:focus {
    background-color: transparent !important;
    outline: none;
  }

  .select-input {
    width: 100%;
  }

  select {
    // color: #8d8d8d !important;
    // color: rgba($color: #fff, $alpha: 0.5) !important;
    // width: 100%;

    option {
      color: #8d8d8d;
    }
  }

  .error-msg {
    color: red;
    font-size: 14px;
    margin-top: 0.25rem;
  }

  .phone-input {
    position: relative;
    display: flex;
    align-items: center;

    select {
      position: absolute;
      height: 100%;
      min-width: 60px;
      text-align: start;
      background-color: transparent;
      color: #fff;
      border: none;
      padding-inline: 0;

      &:focus {
        outline: none;
        border: none;
      }
    }

    input {
      // width: 100% !important;
      [lang='en'] & {
        padding-left: calc(60px + 1.25rem);
      }
      [lang='hr'] & {
        padding-left: calc(60px + 1.25rem);
      }
      [lang='ar'] & {
        padding-right: calc(60px + 1.25rem);
      }
    }
  }
}
