// @font-face {
//   font-family: 'gothamBold';
//   src: url('../../Fonts/Gotham/Gotham-Bold.otf') format('opentype');
// }

// @font-face {
//   font-family: 'gothamMedium';
//   // src: url('../../Fonts/Gotham/Gotham-Medium.otf') format('opentype');
//   src: url('../../Fonts/Gotham/GothamMedium.ttf');
// }

@font-face {
  font-family: 'Frutiger';
  // src: url('../../Fonts/Gotham/Gotham-Medium.otf') format('opentype');
  src: url('../../Fonts/frutiger/Frutiger.woff');
}

// html[lang='en'] {
*,
body {
  font-family: 'Frutiger' !important;
}

.fw--500 {
  font-family: 'Frutiger' !important;
}
// }

// html[lang='ar'] {
//   *,
//   body,
//   .fw--500 {
//     // font-family: 'Cairo', sans-serif !important;
//     font-family: 'Frutiger' !important;
//   }
// }

.fw--500 {
  font-weight: 500;
}
.fw--600 {
  font-weight: 600;
}
.fw--700 {
  font-weight: 700;
}
.fw--800 {
  font-weight: 800;
}

@include to-tablet-sm {
  .fsize--1 {
    font-size: 3.25rem;
    line-height: 68px;
  }
  .fsize--2 {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .fsize--3 {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .fsize--4 {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .fsize--5 {
    font-size: 1rem;
    line-height: 21px;
  }
  .fsize--6 {
    font-size: 1rem;
    line-height: 21px;
  }
  .fsize--7 {
    font-size: 1rem;
    line-height: 21px;
  }
}

@media (max-width: 360px) {
  .fsize--1 {
    font-size: 2.5rem;
    line-height: 52px;
  }
  .fsize--6 {
    font-size: 0.8rem;
    // font-size: 1rem;
    line-height: 21px;
  }
}

@include from-tablet-sm {
  .fsize--1 {
    font-size: 3.25rem;
    line-height: 68px;
  }
  .fsize--2 {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .fsize--3 {
    font-size: 1.75rem;
    line-height: 36px;
  }
  .fsize--4 {
    font-size: 2rem;
    line-height: 42px;
  }
  .fsize--5 {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .fsize--6 {
    font-size: 1.25rem;
    line-height: 26px;
    // font-size: 1rem;
    // line-height: 21px;
  }
  .fsize--7 {
    font-size: 1rem;
    line-height: 21px;
  }
}

@include from-desktop-lg {
  .fsize--1 {
    font-size: 4rem;
    line-height: 83px;
  }
  .fsize--2 {
    font-size: 3.25rem;
    line-height: 68px;
  }
  .fsize--3 {
    font-size: 2.5rem;
    line-height: 52px;
  }
  .fsize--4 {
    font-size: 2rem;
    line-height: 42px;
  }
  .fsize--5 {
    font-size: 1.75rem;
    line-height: 36px;
  }
  .fsize--6 {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .fsize--7 {
    font-size: 1rem;
    line-height: 21px;
  }
}
